/**
 * 防抖函数(控制一些函数执行的次数)
 * 防止函数在极短的时间内反复调用，造成资源的浪费。
 * @param func
 * @param time
 * @returns {function(...[*]=)}
 */
export function debounce(fn, time) {
  let timer = null;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, time)
  }
}
/**
 * 节流函数(控制一些函数执行的次数)
 * 预定一个函数只有在大于等于执行周期的时候才可以执行，周期内调用不会执行。
 * @param func
 * @param wait
 * @returns {function(...[*]=)}
 */
export function throttle(func, wait) {
  let timer = null;
  // 把计时器return出去了，这样每次执行的时候只有一个timer，闭包
  return function (...argument) {
    // 如果计时器没有值，是空的时候，才可以给他绑定计时器，如果有值就不会再绑定计时器
    if (!timer) {
      timer = setTimeout(() => {
        // 调换this指向和把所有形参传入参数
        func.apply(this, argument);
        // 调用完了之后再把计时器清空
        timer = null;
      }, wait)
    }
  }
}